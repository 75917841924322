import React from 'react';

const Features: React.FC = () => {
    return (
        <section className="features">
            <h2>Features</h2>
            <ul>
                <li>AI-Powered Predictions</li>
                <li>Personalized Astrological Insights</li>
                <li>Numerological Analysis</li>
            </ul>
        </section>
    );
};

export default Features;
