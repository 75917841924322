import React from 'react';

const About: React.FC = () => {
    return (
        <section className="about">
            <h2>About CosmoCode.ai</h2>
            <p>CosmoCode.ai blends the mystique of astrology and numerology with the precision of AI to deliver personalized insights just for you.</p>
        </section>
    );
};

export default About;
