import React from 'react';
import {CosmoCodeIcon} from "./CosmoCodeIcon"; // Adjust the path as needed

const Hero: React.FC = () => {
    const scrollToSignup = () => {
        const signupSection = document.getElementById('signup');
        if (signupSection) {
            signupSection.scrollIntoView({behavior: 'smooth'});
        }
    };

    return (
        <section className="hero">
                <CosmoCodeIcon/>
            <h1>Welcome to CosmoCode.ai</h1>
            <p>Unlock the power of AI-driven astrology and numerology.</p>
            <button onClick={scrollToSignup}>
                Join the Waitlist
            </button>
        </section>
    );
};

export default Hero;
