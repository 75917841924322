import React, { useState } from 'react';
import Notification from './Notification';

const SignupForm: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [notification, setNotification] = useState<{ message: string, type: 'success' | 'error' } | null>(null);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();  // Prevent the default form submission

        // Simple email validation regex
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Check if the email is valid
        if (!email.trim() || !emailPattern.test(email)) {
            setNotification({ message: 'Please enter a valid email address.', type: 'error' });
            return;
        }

        // Simulate a successful submission
        setNotification({ message: 'Thank you for joining the waitlist!', type: 'success' });
        setEmail('');  // Clear the input after successful submission
    };

    const closeNotification = () => {
        setNotification(null);
    };

    return (
        <section id="signup" className="signup">
            <h2>Stay Updated</h2>
            <form onSubmit={handleSubmit} id='emailForm' noValidate data-netlify="true">
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={notification && notification.type === 'error' ? 'input-error' : ''}
                />
                <button type="submit">Join the Waitlist!</button>
            </form>
            {notification && (
                <Notification message={notification.message} type={notification.type} onClose={closeNotification} />
            )}
        </section>
    );
};

export default SignupForm;
