import React from 'react';

type NotificationProps = {
    message: string;
    type: 'success' | 'error';
    onClose: () => void;
};

const Notification: React.FC<NotificationProps> = ({ message, type, onClose }) => {
    return (
        <div className={`notification ${type}`}>
            <span>{message}</span>
            <button onClick={onClose} className="close-btn">&times;</button>
        </div>
    );
};

export default Notification;
