import React from 'react';
import Hero from './components/Hero';
import Features from './components/Features';
import About from './components/About';
import SignupForm from './components/SignupForm';
import Footer from './components/Footer';
import './App.css'; // You can style your components here

const App: React.FC = () => {
  return (
      <div className="App">
        <Hero />
        <Features />
        <About />
        <SignupForm />
        <Footer />
      </div>
  );
};

export default App;
